import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static outlets = ["backdrop"]
  static targets = []

  connect() {
    console.log("connect submit-thankyou")
    console.log(this.backdropOutlet)
    this.backdropOutlet.toggle()
  }
  disconnect() {
    console.log("disconnect")
  }
}
