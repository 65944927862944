import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["packageDetail", "invoiceInfo"]

  connect() {
    console.log("hello order summary")
  }

  togglePackageDetail() {
    this.packageDetailTarget.classList.toggle("hidden")
  }

  toggleInvoiceInfo() {
    let invoiceInfo = this.invoiceInfoTarget
    let tax_id = invoiceInfo.querySelector("#tax_id")
    let tax_fullname = invoiceInfo.querySelector("#tax_fullname")
    let tax_address = invoiceInfo.querySelector("#tax_address")

    if(invoiceInfo.classList.contains("hidden")) {
      this.invoiceInfoTarget.classList.remove("hidden")
      tax_id.setAttribute("required", "")
      tax_fullname.setAttribute("required", "")
      tax_address.setAttribute("required", "")
      
    } else {
      this.invoiceInfoTarget.classList.add("hidden")
      tax_id.removeAttribute("required")
      tax_fullname.removeAttribute("required")
      tax_address.removeAttribute("required")
    }
  }
}

